import React from 'react'
import './MainPage.css';
import NavBar from './NavBar';
import Content1 from './Content1';
import welcome from '../videos/Welcome.mp4';
import Slider from "react-slick";
import Logo from './Logo';
import yard from '../videos/Yard.mp4';
import bible from '../videos/BibleStudy.mp4';
import img1 from '../images/WebPage/Wpage (1).jpg'
import img2 from '../images/WebPage/Wpage (2).jpg'
import img3 from '../images/WebPage/Wpage (3).jpg'
import img4 from '../images/WebPage/Wpage (4).jpg'
import img5 from '../images/WebPage/Wpage (5).jpg'
import img6 from '../images/WebPage/Wpage (6).jpg'
import img7 from '../images/WebPage/Wpage (7).jpg'
import img8 from '../images/WebPage/Wpage (8).jpg'
import img9 from '../images/WebPage/Wpage (9).jpg'
import img10 from '../images/WebPage/Wpage (10).jpg'
import img11 from '../images/WebPage/Wpage (11).jpg'
import img12 from '../images/WebPage/Wpage (12).jpg'
import img13 from '../images/WebPage/Wpage (13).jpg'
import img14 from '../images/WebPage/Wpage (14).jpg'
import img15 from '../images/WebPage/Wpage (15).jpg'
import img16 from '../images/WebPage/Wpage (16).jpg'
import img17 from '../images/WebPage/Wpage (17).jpg'
import img18 from '../images/WebPage/Wpage (18).jpg'
import img19 from '../images/WebPage/Wpage (19).jpg'
import img20 from '../images/WebPage/Wpage (20).jpg'

const settings = {
  autoplaySpeed: 7000,
  autoplay: true,
  fade: true,
  dots: false,
  infinite: true,
  speed: 800,
  slidesToShow: 1,
  pauseOnHover: false,
  slidesToScroll: 1,
};


const MainPage = () => {
  return (
<div className='container-fluid'>
 <nav id='mainnav'><NavBar/></nav>
      <main><Content1/></main>
      <div id='mmarquee'>
      <marquee behavior="scroll" direction="left" scrollamount="18">  <h1 id='mh1'>Our Sunday Church Services begin at <strong id='m10am'>10 Am</strong> &nbsp; &nbsp; Everyone is <strong id='m10am'>Welcome,</strong>&nbsp; So come as you are! &nbsp;&nbsp; </h1></marquee>
      </div>
      <div id='content1'><video className='vid' autoPlay muted loop><source src={welcome} /></video></div>
      <div id='content3'><video className='vid' autoPlay muted loop><source src={bible} /></video></div>
      <footer><Logo/></footer>
    </div>

  )
}

export default MainPage
