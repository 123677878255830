
import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyCS-Sw4XFlNuOFTAZRizZa8YuonU0rMwzM",
  authDomain: "churchdb-ed9fb.firebaseapp.com",
  projectId: "churchdb-ed9fb",
  storageBucket: "churchdb-ed9fb.appspot.com",
  messagingSenderId: "305932273216",
  appId: "1:305932273216:web:0a8c0753cdfcda18cbc1a5"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
export { db };