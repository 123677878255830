import React from 'react'
import './Franklin.css'
import bigf from '../images/BigFranklin.png'
import NavBar from './NavBar';
import Logo from './Logo';


const Franklin = () => {
  return (
    <div id='fcontainer-fluid'>
      <div id='fnav'><NavBar/></div>
      <div id='ftitle'><h3 id='fh3'>From: Franklin</h3></div>
      <div id='fimg'><img src={bigf} alt='How to get to Sinners Sanctuary from Franklin, Pa' id='bigfimg'></img></div>
      <div id='ffooter'><Logo/></div>
    </div>
  )
}

export default Franklin
