import React from 'react'
import NavBar from './NavBar';
import './AboutUs.css';
import Logo from './Logo';

const AboutUs = () => {
  return (
<div id='aucontainer-fluid'>
    <nav id='aunav'><NavBar/></nav>
   <div id='autitle'><h1 className='auh1'>About Us</h1></div>
    <aumain id='aumain'>
    <p className='aupar'>In December 2016, four families came together with a dream to start a new church.  This church was not to be an “organized” church.  It was to be non-denominational where anyone could come and worship God.  We wanted it to be an outreach to the community and a place where people could come and not be judged.
            In just two weeks, we found a building and cleaned it out.  This building was a cement block building with a leaky roof and an old wood burning stove as the heat source.  We were able to tap into the water and have a working restroom, but we were primitive.  We held our first service on January 8, 2017.
            All decisions for the church were to be made by the congregation.  On January 15, 2017, we set out to name our church.  Everyone put their ideas into a hat and one was picked, that name was Sinner’s Sanctuary.  What an appropriate name for the start of our new church.
            It was decided that any money that came into the church that was not marked for any other reason, (remember the leaky roof?) would go right back out into the community for those in need.  To this date we have helped hundreds of people with thousands of dollars.   We’ve helped with utility bills, rent, car payments, insurance payments, gas money for those who are traveling due to a loved one in the hospital, etc.  No questions asked, just loving on people.
            We have an annual Easter Egg Hunt where we stuff thousands of eggs and have basket and bike give aways.  We have a Christmas Toy Drive where we have gently used toys donated and any monetary donations go towards purchasing new items.  Again, we have helped hundreds of children to have a Christmas that they may not have gotten.
            In May 2019, we were approached asking if we would like to be gifted an old church in Nectarine.  What?  You will give it to us?  We won’t have to cut and stack wood to heat the building?  Of course we said yes.  In the end it only cost us around $500 to have the deed transferred.  What a blessing!  We held our first service in the “New” building on August 12, 2019.
          </p>
      
    </aumain>
    <aufooter id='aufooter'><Logo/></aufooter>

</div>
  )
}

export default AboutUs
