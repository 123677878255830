import React from 'react'

function AdminNavBar() {
  return (
    <div>
      <section className='navsec'>
        <nav className='navbar" role="navigation" aria-label="main navigation'>
          <dir className='navbar-brand nvdir'>
            <a class="navbar-item nvitem" href='/'>Home</a>
            <a class="navbar-item nvitem" href='clist'>Contact List</a>
            {/* <a class="navbar-item nvitem" href='vlist'>Volunteer List</a> */}
            <a class="navbar-item nvitem" href='plist'>Prayer Requests List</a>
          </dir>
        </nav>
      </section>
    </div>
  )
}

export default AdminNavBar