import React from 'react';
import './Logo.css'
import edsm from '../images/EDSsml.png';

function Logo() {
  return (
    <div className='logo-div'>
      <p className='copy-p'><img src={edsm} alt='My Company Eternal Development Studios' className='edssml'></img> &copy;</p>
    </div>
  )
}

export default Logo;
