
import format from "date-fns/format";
import getDay from "date-fns/getDay";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import React, { useState } from "react";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import NavBar from './NavBar'
import Logo from './Logo'
import './EventsCalendar.css';

const locales = {
  "en-US": require("date-fns/locale/en-US"),
};
const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const events = [
  {
    title: "Services begin @ 10 AM",
    allDay: false,
    start: new Date(2023, 11, 3, 10, 0),
    end: new Date(2023, 11, 3, 11, 30),
  },
  {
    title: "Services begin @ 10 AM",
    allDay: false,
    start: new Date(2023, 11, 10, 10, 0),
    end: new Date(2023, 11, 10, 11, 30),
  },
  {
    title: "Services begin @ 10 AM",
    allDay: false,
    start: new Date(2023, 11, 17, 10, 0),
    end: new Date(2023, 11, 17, 11, 30),
  },
  {
    title: "Services begin @ 10 AM",
    allDay: false,
    start: new Date(2023, 11, 24, 10, 0),
    end: new Date(2023, 11, 24, 11, 30),
  },
  {
    title: "Services begin @ 10 AM",
    allDay: false,
    start: new Date(2023, 11, 31, 10, 0),
    end: new Date(2023, 11, 31, 11, 30),
  },
  {
    title: "Men's Bible Study 7PM",
    allDay: false,
    start: new Date(2023, 11, 4, 19, 0),
    end: new Date(2023, 11, 4, 20, 30),
  },
  {
    title: "Men's Bible Study 7PM",
    allDay: false,
    start: new Date(2023, 11, 11, 19, 0),
    end: new Date(2023, 11, 11, 20, 30),
  },
  {
    title: "Men's Bible Study 7PM",
    allDay: false,
    start: new Date(2023, 11, 18, 19, 0),
    end: new Date(2023, 11, 18, 20, 30),
  },
  {
    title: "Everyone Bible Study 6:30PM",
    allDay: false,
    start: new Date(2023, 11, 5, 18, 30),
    end: new Date(2023, 11, 5, 19, 30),
  },
  {
    title: "Everyone Bible Study 6:30PM",
    allDay: false,
    start: new Date(2023, 11, 12, 18, 30),
    end: new Date(2023, 11, 12, 19, 30),
  },
  {
    title: "Everyone Bible Study 6:30PM",
    allDay: false,
    start: new Date(2023, 11, 19, 18, 30),
    end: new Date(2023, 11, 19, 19, 30),
  },

  {
    title: "Youth Group 6:30PM",
    allDay: false,
    start: new Date(2023, 11, 7, 18, 30),
    end: new Date(2023, 11, 7, 19, 30),
  },
  {
    title: "Youth Group 6:30PM",
    allDay: false,
    start: new Date(2023, 11, 14, 18, 30),
    end: new Date(2023, 11, 14, 19, 30),
  },
  {
    title: "Youth Group 6:30PM",
    allDay: false,
    start: new Date(2023, 11, 21, 18, 30),
    end: new Date(2023, 11, 21, 19, 30),
  },
  {
    title: "Youth Group 6:30PM",
    allDay: false,
    start: new Date(2023, 11, 28, 18, 30),
    end: new Date(2023, 11, 28, 19, 30),
  },
  {
    title: "Saturday Night Jam",
    allDay: false,
    start: new Date(2023, 11, 30, 18, 30),
    end: new Date(2023, 11, 30, 0, 0),
  },
  {
    title: "Toy Giveaway 9Am to 3PM",
    allDay: false,
    start: new Date(2023, 11, 9, 9, 0),
    end: new Date(2023, 11, 9, 15, 0),
  },
  {
    title: "Toy Giveaway 9Am to 3PM",
    allDay: false,
    start: new Date(2023, 11, 16, 9, 0),
    end: new Date(2023, 11, 16, 15, 0),
  },
  {
    title: "Merry Christmas",
    allDay: true,
    start: new Date(2023, 11, 25, 0, 0),
    end: new Date(2023, 11, 25, 0, 0),
  },
  {
    title: "Bible Study Cancelled",
    allDay: false,
    start: new Date(2023, 10, 28, 0, 0),
    end: new Date(2023, 10, 28, 0, 0),
  },



];

function EventsCalendar() {
  const auth = true;
  const [newEvent, setNewEvent] = useState({ title: "", start: "", end: "" });
  const [allEvents, setAllEvents] = useState(events);

  // function handleAddEvent() {
  //   setAllEvents([...allEvents, newEvent]);
  // }

  return (
    <section className="evsect">
      <div>
        <div>
          <NavBar />
        </div>
        {/* <h1 className="evh1">Church Events Calendar</h1> */}
        {/* <h2 className="evh2">Add New Event</h2> */}
        {/* <div className="evdiv">
        <input type="text" placeholder="Add Title" value={newEvent.title} onChange={(e) => setNewEvent({ ...newEvent, title: e.target.value })}></input>
      </div>
      <div>
        <DatePicker placeholderText="Start Date" selected={newEvent.start} onChange={(start) => setNewEvent({ ...newEvent, start })} />
      </div>
      <div>
        <DatePicker placeholderText="End Date" selected={newEvent.end} onChange={(end) => setNewEvent({ ...newEvent, end })} />
      </div>
      <div className="evbtn">
        <button onClick={handleAddEvent}>
          Add Event
        </button>
      </div> */}
        <Calendar localizer={localizer} events={allEvents} startAccessor="start" endAccessor="end" style={{ height: 500, margin: "50px" }} />
        <div>
          <Logo />
        </div>
      </div>
    </section>
  );
}

export default EventsCalendar;