import { useState, useEffect } from 'react'
import axios from "axios";
import { Link } from "react-router-dom";
import './PrayerList.css'
import AdminNavBar from './AdminNavBar';

const PrayerList = () => {
  const [prayer, setPrayer] = useState([]);

  useEffect(() => {
    getPrayer();
  }, []);

  const getPrayer = async () => {
    const response = await axios.get('http://localhost:5000/prayer');
    setPrayer(response.data);
  }

  const deletePrayer = async (id) => {
    await axios.delete(`http://localhost:5000/prayer/${id}`);
    getPrayer();
  }

  return (
    <div>
      <AdminNavBar />
      <div className='amh1div'>
        <h1 className='amh1'>Prayer Request List</h1>
      </div>
      <Link to="/add" className="button is-primary mt-2">Add New</Link>
      <table className="table is-striped is-fullwidth">
        <thead>
          <tr>
            <th id='plthn'>No</th>
            <th id='plthfn'>FirstName</th>
            <th id='plthln'>LastName</th>
            <th id='plthcmt'>PrayerRequest</th>
            <th id='plthca'>Created @</th>
          </tr>
        </thead>
        <tbody>
          {prayer.map((prayer, index) => (
            <tr key={prayer.id}>
              <td>{index + 1}</td>
              <td id='pltdfn'>{prayer.f_name}</td>
              <td id='pltdln'>{prayer.l_name}</td>
              <td id='pltdcmt'>{prayer.commt}</td>
              <td id='pltdcmt'>{prayer.createdAt}</td>
              <td>
                <Link to={`/edit/${prayer.id}`} className="button is-small is-info">Edit</Link>
                <button onClick={() => deletePrayer(prayer.id)} className="button is-small is-danger">Delete</button>
              </td>
            </tr>
          ))}

        </tbody>
      </table>
    </div>
  )
}

export default PrayerList