import React, { useState, useEffect } from 'react';
import { db } from '../firebase-config'
import { collection, doc, getDocs, deleteDoc } from 'firebase/firestore'
import './ContactList.css'
import AdminNavBar from './AdminNavBar';

const ContactList = () => {
  const [contacts, setContacts] = useState([])
  const usersCollectionRef = collection(db, "contacts")
  const deleteContact = async (id) => {
    const userDoc = doc(db, "contacts", id);
    await deleteDoc(userDoc);
  }
  useEffect(() => {
    const getUsers = async () => {
      const data = await getDocs(usersCollectionRef);
      setContacts(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };
    getUsers();
  }, []);
  return (
    <div>
      <div>
        <AdminNavBar />
      </div>
      <div className='ch1div'>
        <h1 className='cch1'>Contact List</h1>
      </div>
      {contacts.map((contacts) => {
        return (
          <div>
            <section className='ccontainer'>
              <div className='cfndiv'>
                <h1 className='ch1'>Firstname</h1>
                <div id='cfninfo'>
                  {contacts.f_name}
                </div>
              </div>
              <div className='lndiv'>
                <h1 className='ch1'>Lastname:</h1>
                <div id='lninfo'>
                  {contacts.l_name}
                </div>
              </div>
              <div className='chpdiv'>
                <h1 className='ch1'>Home Phone:</h1>
                <div id='chpinfo'>
                  {contacts.phone}
                </div>
              </div>

              <div className='emdiv'>
                <h1 className='ch1'>E-Mail</h1>
                <div id='eminfo'>
                  {contacts.email}
                </div>
              </div>
              <div className='ccmtdiv'>
                <h1 className='ch1'>Message:</h1>
                <div id='cmtinfo'>
                  {contacts.message}
                </div>
              </div>

              <div className='btndiv'>
                <button className='cbtn' onClick={() => { deleteContact(contacts.id) }}>Delete Request</button>
              </div>
            </section>
          </div>
        );
      })}
    </div>
  )
}
export default ContactList;