import React from 'react';
import './Contact.css';
import NavBar from './NavBar';
import AddContact from './AddContact';
import Logo from './Logo'

const Contact = () => {
  return (
    <div id='cucontainer-fluid'>
        <div id='cunav'><NavBar/></div>
        <div id='cutitle'><h1>Contact Info</h1></div>
        <div id='cuaddress'><h4 id='cuh4'>Sinner's Sanctuary Church<br/>
2178 Clintonville Road<br/>
Harrisville, Pa. 16038</h4></div>
        <div id='cuinfo'><h5 id='cuh5'>Pastor:&nbsp; Sam Stebbins:&nbsp;&nbsp; 724-813-8959<br/><br/>Children's Dept:&nbsp; Bekah Stebbins:&nbsp;&nbsp; 724-815-1076<br/><br/>Men's Ministry:&nbsp; Rick:&nbsp;&nbsp;443-955-1187<br/><br/>Youth Ministry:&nbsp; <br/><br/>Bulletins & Announc:&nbsp; Becky Stebbins:&nbsp;&nbsp; 724-967-3888</h5></div>
        <div id='cuctitle'><h1>Contact Form</h1></div>
        <div id='cuform'><AddContact/></div>
        <div id='cufooter'><Logo/></div>
    </div>
  )
}

export default Contact
