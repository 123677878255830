import React from 'react';
import MainPage from './Components/MainPage';
import {Routes, Route} from 'react-router-dom'
import AboutUs from './Components/AboutUs';
import Backpack from './Components/Backpack';
import Contact from './Components/Contact';
import Directions from './Components/Directions';
import Barkey from './Components/Barkey';
import Butler from './Components/Butler';
import Franklin from './Components/Franklin';
import Clinton from './Components/Clinton';
import EventsCalendar from './Components/EventsCalendar';
import Admin from './Components/Admin';
import AdminNavBar from './Components/AdminNavBar';
import ContactList from './Components/ContactList';
import PrayerList from './Components/PrayerList';
import AddPrayer from './Components/AddPrayer';

function App() {
  return (
    <Routes>
      <Route path='/' element={<MainPage />} />
      <Route path='aboutus' element={<AboutUs/>} />
      <Route path='viewbpl' element={<Backpack/>} />
      <Route path='contact' element={<Contact/>} />
      <Route path='directions' element={<Directions/>} />
      <Route path='barkey' element={<Barkey/>}/>
      <Route path='butler' element={<Butler/>}/>
      <Route path='franklin' element={<Franklin/>}/>
      <Route path='clintonville' element={<Clinton/>}/>
      <Route path='eventscalendar' element={<EventsCalendar/>}/>
      <Route path='admin' element={<AdminNavBar/>}/>
      <Route path='clist' element={<ContactList/>}/>
      <Route path='plist' element={<PrayerList/>}/>
      <Route path='prayer' element={<AddPrayer/>}/>
    </Routes>
  );
}
export default App;