import React from 'react'
import './Barkey.css'
import bigbar from '../images/BigBarkeyville.png'
import NavBar from './NavBar';
import Logo from './Logo';

const Barkey = () => {
  return (
    <div id='barcontainer-fluid'>
      <div id='barnav'><NavBar/></div>
      <div id='bartitle'><h3 id='barh3'>From: Barkeyville</h3></div>
      <div id='barimg'><img src={bigbar} alt='Barkeyville Map' id='bigbimg'></img></div>
      <div id='barkfooter'><Logo/></div>
    </div>
  )
}
export default Barkey
