import React from 'react'
import './Butler.css'
import bigbu from '../images/BigButler.png';
import NavBar from './NavBar';
import Logo from './Logo';


const BigButler = () => {
  return (
    <div id='bucontainer-fluid'>
      <div id='bunav'><NavBar/></div>
      <div id='butitle'><h3 id='buh3'>From: Butler</h3></div>
      <div ><img src={bigbu} alt='Butler Map' id='buimg'></img></div>
      <div id='bufooter'><Logo/></div>
    </div>
  )
}

export default BigButler
