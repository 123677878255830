import React from 'react'
import './Clinton.css'
import bigc from '../images/BigClintonville.png'
import NavBar from './NavBar';
import Logo from './Logo';


const Clinton = () => {
  return (
    <div id='ccontainer-fluid'>
      <div id='cnav'><NavBar/></div>
      <div id='ctitle'><h3 id='ch3'>From: Clintonville</h3></div>
      <div id='cimg'><img src={bigc} alt='How to get here from Clintonville' id='bigcimg'></img></div>
      <div id='cfooter'><Logo/></div>
    </div>
  )
}

export default Clinton
