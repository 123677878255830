import React from 'react'
import { db } from '../firebase-config'
import { useState, useEffect } from "react"
import {
  collection,
  onSnapshot,
  addDoc
} from "firebase/firestore"
import { Row, Col, Form } from 'react-bootstrap'
import './AddContact.css'

const AddContact = () => {
  const [contacts, setContacts] = useState([])
  const [form, setForm] = useState({
    f_name: "",
    l_name: "",
    phone: "",
    email: "",
    message: ""
  })


  const contactsCollectionRef = collection(db, "contacts")

  useEffect(() => {
    onSnapshot(contactsCollectionRef, snapshot => {
      setContacts(snapshot.docs.map(doc => {
        return {
          id: doc.id,
          viewing: false,
          ...doc.data()
        }
      }))
    })
  }, [])

  const handleSubmit = e => {
    e.preventDefault()
    if (
      !form.f_name ||
      !form.l_name ||
      !form.phone ||
      !form.email ||
      !form.message
    ) {
      alert("Please fill out all fields")
      return
    }
    addDoc(contactsCollectionRef, form)
    setForm({
      f_name: "",
      l_name: "",
      phone: "",
      email: "",
      message: ""
    })
  }
  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <Row className='mb-3'>
          <Form.Group as={Col} controlID="formGridFirstName">
            <Form.Control className='cinput'
              type='text'
              placeholder="First Name"
              value={form.f_name}
              onChange={e => setForm({ ...form, f_name: e.target.value })} />
          </Form.Group>
          <Form.Group as={Col} controlID="formGridLastName">
            <Form.Control className='cinput'
              type='text'
              placeholder="Last Name"
              value={form.l_name}
              onChange={e => setForm({ ...form, l_name: e.target.value })} />
          </Form.Group>
        </Row>
        <Form.Group controlID="formGridPhone">    
          <Form.Control className='cinput'
            type='text'
            placeholder="555-555-5698"
            value={form.phone}
            onChange={e => setForm({ ...form, phone: e.target.value })} />
        </Form.Group>
        <Form.Group controlID="formGridEmail">
          <Form.Control className='cinput'
            type='text'
            placeholder="jdoe@gmail.com"
            value={form.email}
            onChange={e => setForm({ ...form, email: e.target.value })} />
        </Form.Group>
        <Form.Group controlID="formGridMessage">
          <Form.Control className='cinput'
            as="textarea"
            rows={6}
            type='text'
            placeholder="Whatever you wish to put in here about yourself or family"
            value={form.message}
            onChange={e => setForm({ ...form, message: e.target.value })} />
        </Form.Group>
        <div className='cbtndiv'>
          <button className='cbtn' type="submit">Submit Contact Info</button>
        </div>
      </Form>
    </div>
  )
}

export default AddContact