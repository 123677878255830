import { useState } from 'react';
import  styles from './Navbar.module.css';

function NavBar() {
  // adding the states 
  const [isActive, setIsActive] = useState(false);

  //add the active class
  const toggleActiveClass = () => {
    setIsActive(!isActive);
  };

  //clean up function to remove the active class
  const removeActive = () => {
    setIsActive(false)
  }

  return (
    <div className="App">
      <header className="App-header">

        <nav className={`${styles.navbar}`}>

          {/* logo */}
          {/* <a href='#home' className={`${styles.logo}`}>Dev. </a> */}

          <ul className={`${styles.navMenu} ${isActive ? styles.active : ''}`}>
            <li onClick={removeActive}>
            <a className={`${styles.navLink}`} href='/'>Home</a>
            </li>
            <li onClick={removeActive}>
            <a className={`${styles.navLink}`} href='aboutus'>About Us</a>
            </li>
           
            <li onClick={removeActive}>
            <a className={`${styles.navLink}`} href='contact'>Contact Us</a>
            </li>
            <li onClick={removeActive}>
            <a className={`${styles.navLink}`} href='eventscalendar'>Events Calendar</a>
            </li>
            <li onClick={removeActive}>
            <a className={`${styles.navLink}`} href='directions'>Directions</a>
            </li>
          </ul>

          <div className={`${styles.hamburger} ${isActive ? styles.active : ''}`}  onClick={toggleActiveClass}>
            <span className={`${styles.bar}`}></span>
            <span className={`${styles.bar}`}></span>
            <span className={`${styles.bar}`}></span>
          </div>
        </nav>

      </header>
    </div>
  );
}

export default NavBar;