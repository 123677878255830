import React from 'react'
import './Directions.css';
import NavBar from './NavBar';
import franklin from '../images/Franklin.png';
import clintonville from '../images/Clintonville.png';
import barkeyville from '../images/Barkeyville.png';
import butler from '../images/Butler.png';
import Logo from './Logo';

const Directions = () => {
  return (
    <div id='dcontainer-fluid'>
        <div id='dnav'><NavBar/></div>
        <div id='dtitle1'><h2>Directions to:</h2></div>
        <div id='dtitle2'><h1 id='dh1'>Sinner's Sanctuary</h1></div>
        <div id='dftitle'><h3>From: Franklin</h3></div>
        <div id='dfrank'><a href='franklin'><img id='mapimgf' src={franklin} alt='How to get to Sinners Sanctuary from Franklin, Pa' ></img></a></div>
        <div id='dctitle'><h3>From: Clintonville</h3></div>
        <div id='dclinton'><a href='clintonville'><img id='mapimgc' src={clintonville} alt='How to get to Sinners Sanctuary from Clintonville, Pa'></img></a></div>
        <div id='dbtitle'><h3>From: Barkeyville</h3></div>
        <div id='dbarkey'><a href='barkey'><img id='mapimgb' src={barkeyville} alt='How to get to Sinners Sanctuary from Barkeyville, Pa'></img></a></div>
        <div id='dbutitle'><h3>From: Butler</h3></div>
        <div id='dbutler'><a href='butler'><img id='mapimgb2' src={butler} alt='How to get to Sinners Sanctuary from Butler, Pa'></img></a></div>
        <div id='dfooter'><Logo/></div>
    </div>
  )
}

export default Directions
