import React from 'react'
import './Backpack.css';
import NavBar from './NavBar';

export default function Backpack() {
  return (
<section className='bpsect'>
    <div>
        <NavBar/>
    </div>
<div className='bptitle'><h2 className='bph2'>Backpack Items Needed</h2></div>
<div className='contdiv'>
    <h4>Pudding Cups<br/>
Fruit Snacks<br/>
Individual Wrapped Candy (Maybe Holiday Themed)<br/>
Applesauce<br/>
Nature Valley Bars Or Similar<br/>
Fruit Bars<br/>
Jello Cups<br/>
Small Bags Of Chips/Pretzels<br/>
Single Cookie Packs<br/>
Cheese/Cracker Snacks<br/>
Chewy Bars<br/>

If You Can Help Call Tina @ 814-388-0064<br/>

Thank You In Advance!<br/>
</h4>
</div>
</section>
  )
}


