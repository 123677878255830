import { useState } from 'react'
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import './AddPrayer.css';
import NavBar from './NavBar';
import Logo from './Logo';

const AddPrayer
  = () => {
    const [f_name, setF_Name] = useState('');
    const [l_name, setL_Name] = useState('');
    const [e_mail, setE_Mail] = useState('');
    const [commt, setComment] = useState('');
    const navigate = useNavigate();


    const SavePrayer = async (e) => {
      e.preventDefault();
      await axios.post('http://localhost:5000/prayer', {
        f_name: f_name,
        l_name: l_name,
        e_mail: e_mail,
        commt: commt
      });
      navigate("/");
    }

    return (
      <div id='prayercontainer-fluid'>
        <div id='apnav'><NavBar/></div>
        <div id='aptitle'><h2>Prayer Request</h2></div>
        <div id='apfirst'>
        <input type='text'
        placeholder='First Name'
        id='fncolor'
        value={f_name} onChange={(e) => setF_Name(e.target.value)} /> </div>
        <div id='aplast'>
        <input 
        type="text"
        placeholder="Last Name"
        id='lncolor'
        value={l_name} onChange={(e) => setL_Name(e.target.value)} /></div>
        <div id='apmail'>
        <input 
        type="email"
        placeholder="E-Mail Address"
        id='emcolor'
        value={e_mail} onChange={(e) => setE_Mail(e.target.value)} /></div>       
        <div id='apmes'>
        <textarea 
        id='apmes' rows="9" cols="40"
        placeholder="Tell us your Prayer Request"
        value={commt} onChange={(e) => setComment(e.target.value)} />
        </div>
        <div id='apbtn'><button onClick={SavePrayer} id='apsubmit'>Submit</button></div>
        <div id='apspace'></div>
        <div id='apfoot'>Footer</div>
      </div>
  
    )
  }

export default AddPrayer