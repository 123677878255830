import React from 'react'
import './Content1.css'
import logo from '../images/Logo4.png'

const Content1 = () => {
  return (
 <section className='mpsect1'>
  <center className='center'>
<img src={logo} alt='Church Logo' className='mplogo'></img>
<div className='pardiv'>
<p className='versepar'><h3 id='parh2'>"For I have not come to call the righteous, but sinners".<br/>Matthew 9:13</h3></p>
</div>
<div className='fbdiv'>
<a href='https://www.facebook.com/pg/sinnerchurch/about/'><h4 className='mph4'>Follow Us On Facebook</h4></a>
</div>
</center>
 </section>
  )
}
export default Content1
